<template>
	<div>
		<vx-card>
			<div class="vx-row">
				<div class="vx-col w-full mb-2">
					<label class="vs-input--label">Domains*</label>
					<div v-if="takedownDomains.length != 0">
						<template v-if="formData['domains'].length">
							<vs-chip @click="removeDomain(item)" v-for="item in formData['domains']" :key="item['domain_id']" closable>{{ item['domain'] }}</vs-chip>
						</template>
						<template v-else>
							<vs-chip>No domains selected</vs-chip>
						</template>
					</div>
					<vs-textarea v-if="takedownDomains.length == 0" class="w-full" v-model="formData['domainsManual']" />
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Source*</label>
					<v-select class="w-full" v-model="formData['source']" :options="formSources" :clearable="false"></v-select>
				</div>
				<div class="vx-col sm:w-1/2 w-full mb-2">
					<label class="vs-input--label">Reason*</label>
					<v-select class="w-full" v-model="formData['reason']" :options="formReasons" :disabled="this.formData['source'] == ''" :clearable="false"></v-select>
				</div>
				<div class="vx-col w-full mb-2">
					<label class="vs-input--label">Notes</label>
					<vs-textarea class="w-full" v-model="formData['notes']" />
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButton" id="button-with-loading" class="mb-2" @click="openConfirm()" :disabled="user_role != 'admin' || (!formInit['done'] || (formData['domains'].length == 0 && formData['domainsManual'] == ''))">Submit</vs-button>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>
	import vSelect from 'vue-select'

	export default{
		components: {
			'v-select': vSelect,
		},
		data() {
			return {
				formData: {
					reason: '',
					source: '',
					notes: '',
					domains: [],
					domainsManual: '',
				},
				formInit: {
					done: false,
					reasons: [],
					sources: [],
				},
				takedownNoticeAlert: false,
			}
		},
		watch: {
			'formData.source': function () {
				// Clear Reason
				this.formData['reason'] = '';
			}
		},
		computed: {
			anomalyFilter: function(){
				return this.$store.getters.getAnomalyFilter;
			},
			takedownDomains: function(){
				return this.$store.getters.getTakedownDomains;
			},
			formSources: function(){
				let sourcesArray = this.formInit['sources'].map(x => {
					return {
						label: x['name'],
						value: x['id']
					}
				});
				
				return sourcesArray;
			},
			formReasons: function(){
				let reasonsArray;

				if(this.formData['source'] != '') {
					reasonsArray = this.formInit['reasons'].filter(x => x.source_id == this.formData['source']['value'] || x.id == 217 || x.id == 218).map(x => {
						return {
							label: x['name'],
							value: x['id']
						}
					});

					// NOTE: If 0 categories are available for source (Only 217 & 218 are populated)
					if (reasonsArray.length == 2) {
						reasonsArray = this.formInit['reasons'].filter(x => x.source_id == 0).map(x => {
							return {
								label: x['name'],
								value: x['id']
							}
						});
					}
				}
				else {
					reasonsArray = this.formInit['reasons'].map(x => {
						return {
							label: x['name'],
							value: x['id']
						}
					});
				}

				return reasonsArray;
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading > .con-vs-loading");
			},
			openConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Manually takedown the selected domains?',
					accept: this.suspendDomains
				});
			},
			suspendDomains() {
				let self = this,
					postURL;
				// Show loader
				this.showButtonLoading();
				// Assign Post URL
				if(this.formData['domainsManual'] != '') {
					postURL = '/suspend-domains-manual';
				}
				else {
					postURL = '/suspend-domains';
				}
				// Post Axios Call
				this.$axiosSecure.post(postURL, {
					...self.formData,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((response) => {
					if (response.data.errorArray.length) {
						// Open info popup
						self.$vs.dialog({
							type: 'confirm',
							color: 'primary',
							title: 'Please Note',
							text: 'The following domains were erroneous & hence ignored: '+response.data.errorArray.join(', '),
							accept: self.showTakedownSuccess,
							acceptText: 'Ok',
							buttonCancel: 'hidden'
						});
					}
					else {
						// Success Confirmation
						self.showTakedownSuccess();
					}
				})
				.catch((error) => {
					// Hide loader
					self.hideButtonLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			showTakedownSuccess() {
				// Confimation notification
				this.$vs.notify({
					color: 'success',
					title: 'Manual takedown success',
					text: 'The selected domains have been queued for takedown'
				});
				
				// Hide loader
				this.hideButtonLoading();
				this.$router.push({ path: '/action-review' });
			},
			removeDomain(item) {
				this.removeFromArray(this.formData['domains'], item, 'domain_id');
			}
		},
		mounted() {
			let self = this;
			// Get Sources JSON
			this.$axiosSecure.get('/get-sources')
			.then((response) => {
				self.formInit['sources'] = response.data;
				// Prepopulate Source
				self.formData['source'] = self.anomalyFilter && self.anomalyFilter['source'] ? self.anomalyFilter['source'] : {label:'Proactive Analysis', value:17};

				// Get Categories JSON
				self.$axiosSecure.get('/get-categories')
				.then((response) => {
					self.formInit['reasons'] = response.data;
					// Prepopulate Reason
					self.formData['reason'] = self.anomalyFilter && self.anomalyFilter['category'] ? self.anomalyFilter['category'] : {label:'Malware/Phishing', value:176};
					// Enable Submit
					self.formInit['done'] = true;
				})
				.catch((error) => {
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			})
			.catch((error) => {
				// Error notification
				self.$vs.notify({
					color: 'danger',
					title: 'Something went wrong',
					text: 'Please contact the server admin'
				});

				console.log(error);
			});

			// Prepopulate form
			this.formData['domains'] = this.takedownDomains.map(x => x);
		},
		destroyed() {
			// Clear Takedown Domains
			this.$store.dispatch("clearTakedownDomains");
		}
	}
</script>
